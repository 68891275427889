package com.kmpalette.demo.images

import io.github.skeptick.libres.images.Image

public actual object ResImages {
  public actual val bg_6: Image
    get() = "images/bg_6.jpg"

  public actual val bg_10: Image
    get() = "images/bg_10.jpg"

  public actual val bg_9: Image
    get() = "images/bg_9.jpg"

  public actual val bg_2: Image
    get() = "images/bg_2.jpg"

  public actual val bg_5: Image
    get() = "images/bg_5.jpg"

  public actual val bg_3: Image
    get() = "images/bg_3.jpg"

  public actual val bg_1: Image
    get() = "images/bg_1.jpg"

  public actual val bg_8: Image
    get() = "images/bg_8.jpg"

  public actual val bg_7: Image
    get() = "images/bg_7.jpg"

  public actual val bg_4: Image
    get() = "images/bg_4.jpg"
}
