package com.kmpalette.demo

import com.kmpalette.demo.images.ResImages
import com.kmpalette.demo.strings.ResStrings

public object Res {
  public val string: ResStrings = ResStrings

  public val image: ResImages = ResImages
}
